const urls = {
  SIGN_IN: "validateAuth",
  CHANGE_PASSWORD: "changePassword",
  GET_ALL_CATEGORIES: "getAllCategoriesBySurgeon",
  GET_CATEGORY: "getAllCategoriesBySurgeon",
  GET_SURVEYBY_CATEGORY: "getSurveysByCategory",
  ADD_PATIENT: "addPatient",
  GET_ALL_PATIENTS: "getAllPatients",
  UPDATE_PATIENT: "updatePatient",
  GET_PATIENT_DETAILS: "getPatient",
  GET_SURVEY_DETAILS: "getSurvey",
  ENABLE_DISABLE_PATIENT: "enableDisablePatient",
  GET_ALL_SURVEYS: "getAllSurveysBySurgeon",
  GET_PROFILE: "getProfile",
  GET_PATIENT_REPORT_DETAILS: "getPatientReportDetails",
  GET_PATIENT_REPORT_DETAILS_BY_ID: "getSurveyTaskById",
  ACCEPT_PRIVACY_POLICY: "acceptPrivacyPolicy",
  GET_DAY_GRAPH_REPORT_DETAILS: "getPatientDayGraphReportDetails",
  GET_YEARLY_GRAPH_REPORT_DETAILS: "getPatientYearGraphReportDetails",
  GET_PATIENT_CSV_REPORT: "getPatientCSVReportDetails",
  FORGOT_PASSWORD: "forgotPassword",
};

export default urls;

import { signIn, changePassword, forgotPassword } from "./services/auth";
import {
  getCategories,
  getAllCategories,
  getSurveyByCategory,
  addPatient,
  getAllPatients,
  updatePatient,
  getPatientDetailsById,
  getSurveyDetailsById,
  enableDisablePatient,
  getAllSurveys,
  getProfile,
  getPatientReportDetails,
  getPatientReportDetailsById,
  acceptPrivacyPolicy,
  getPatientDayGraphReportDetails,
  getPatientYearGraphReportDetails,
  getPatientCSVReportDetails,
} from "./services/surgeon";

const API_SERVICE = {
  signIn,
  changePassword,
  forgotPassword,
  getCategories,
  getAllCategories,
  getSurveyByCategory,
  addPatient,
  getAllPatients,
  updatePatient,
  getPatientDetailsById,
  getSurveyDetailsById,
  enableDisablePatient,
  getAllSurveys,
  getProfile,
  getPatientReportDetails,
  getPatientReportDetailsById,
  acceptPrivacyPolicy,
  getPatientDayGraphReportDetails,
  getPatientYearGraphReportDetails,
  getPatientCSVReportDetails,
};

export { API_SERVICE };

import API from "../utils";
import urls from "../urls";

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const signIn = async (payload) => API.post(urls.SIGN_IN, payload);

export const changePassword = async (payload) => API.post(urls.CHANGE_PASSWORD, payload);

export const forgotPassword = async (payload) => API.post(urls.FORGOT_PASSWORD, payload);

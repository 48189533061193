import * as yup from "yup";

const validation = {
  firstName: yup.string().required("First name is required"),
  lastName: yup.string(),
  email: yup
    .string()
    .email("Invalid email")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be minimum 6 characters")
    .matches(/^(?=.*[0-9])/, "Password must contain at least one number")
    .required("Password is required"),
  dateOfBirth: yup
    .date("Invalid date format")
    .nullable()
    .max(new Date(), "Date of birth cannot be in the future")
    .required("Date of birth is required"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
    .required("Phone number is required"),
};

export default validation;

import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import appIcon from "../../../assets/icons/appLogo.png";
import UserLayout from "../../../layout/userLayout";
import useLogin from "./util";

const LoginScreen = () => {
  const { email, password, formError, onChangeEmail, onChangePassword, onSubmit } = useLogin();
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const onToggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prev) => !prev);
  };

  return (
    <UserLayout>
      <div className="login-card">
        <div className="text-center">
          <img src={appIcon} width="100px" alt="logo" />
        </div>
        <h2 className="auth-title">PROM-IN-ENT</h2>
        <h3 className="login-title">Login</h3>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Control className="app-auth-input login-input" type="email" placeholder="Enter Email" value={email} onChange={onChangeEmail} />
            {formError.email && <div className="form-error">{formError.email}</div>}
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <div className={isFocused ? "password-input-container pic-focused" : "password-input-container"}>
              <Form.Control
                className="password-input"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={password}
                onChange={onChangePassword}
              />
              <button className="btn-blank show-password-btn" onClick={onToggleShowPassword}>
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            {formError.password && <div className="form-error">{formError.password}</div>}
          </Form.Group>
          <div className="forgot-password-container">
            <Link className="forgot-password-link" to="/user/forgot-password">
              Forgot Password?
            </Link>
          </div>
          <div className="text-end">
            <Button className="mt-4 auth-btn" variant="primary" type="submit" onClick={onSubmit}>
              Login
            </Button>
          </div>
        </Form>
        <ToastContainer />
      </div>
    </UserLayout>
  );
};

export default LoginScreen;

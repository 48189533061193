import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import appIcon from "../../../assets/icons/appLogo.png";
import UserLayout from "../../../layout/userLayout";
import useResetPassword from "./util";

const ResetPassword = () => {
  const { newPassword, newPassword2, formError, onChangeNewPassword, onChangeNewPassword2, onSubmit } = useResetPassword();
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const onToggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prev) => !prev);
  };

  const handleFocus2 = () => {
    setIsFocused2(true);
  };

  const handleBlur2 = () => {
    setIsFocused2(false);
  };

  const onToggleShowPassword2 = (e) => {
    e.preventDefault();
    setShowPassword2((prev) => !prev);
  };

  return (
    <UserLayout>
      <div className="login-card">
        <div className="text-center">
          <img src={appIcon} width="100px" alt="logo" />
        </div>
        <h2 className="auth-title">PROM-IN-ENT</h2>
        <h3 className="login-title">Change Password</h3>
        <Form.Group controlId="newPassword">
          <div className={isFocused ? "password-input-container pic-focused" : "password-input-container"}>
            <Form.Control
              className="password-input"
              type={showPassword ? "text" : "password"}
              placeholder="New Password"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={newPassword}
              onChange={onChangeNewPassword}
            />
            <button className="btn-blank show-password-btn" onClick={onToggleShowPassword}>
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
          {formError.newPassword && <div className="form-error">{formError.newPassword}</div>}
        </Form.Group>
        <Form.Group controlId="confirmNewPassword">
          <div className={isFocused2 ? "password-input-container pic-focused" : "password-input-container"}>
            <Form.Control
              className="password-input"
              type={showPassword2 ? "text" : "password"}
              placeholder="Confirm New Password"
              onFocus={handleFocus2}
              onBlur={handleBlur2}
              value={newPassword2}
              onChange={onChangeNewPassword2}
            />
            <button className="btn-blank show-password-btn" onClick={onToggleShowPassword2}>
              {showPassword2 ? "Hide" : "Show"}
            </button>
          </div>
          {formError.confirmNewPassword && <div className="form-error">{formError.confirmNewPassword}</div>}
        </Form.Group>
        <div className="text-end">
          <Button className="mt-4 auth-btn" variant="primary" type="submit" onClick={onSubmit}>
            Change Password
          </Button>
        </div>
        <ToastContainer />
      </div>
    </UserLayout>
  );
};

export default ResetPassword;

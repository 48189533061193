import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import * as yup from "yup";
import { errorWrapper, handleError } from "../../../helpers";
import { Loader } from "../../../utils/appLoader";
import { NotificationManager } from "../../../utils/notificationManager";
import { validateData, validation } from "../../../validation";
import { API_SERVICE } from "../../../api";

const useForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  const firstRender = useRef(true);

  let userSchema = yup.object({
    email: validation.email,
  });

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const sendEmail = (inputData) => {
    return new Promise((resolve, reject) => {
      const requestBody = {
        email: inputData.email.trim(),
      };

      API_SERVICE.forgotPassword(requestBody)
        .then((res) => {
          if (res.httpStatus === "OK") {
            resolve(true);
          } else {
            Loader.hide();
            handleError(res, "Something went wrong");
          }
        })
        .catch((err) => reject(err));
    });
  };

  const checkFormError = async () => {
    let payload = {
      email: email,
    };
    const { errors } = await validateData(userSchema, payload);
    if (errors) {
      setFormError(errors);
      return true;
    }
    setFormError(false);
    return false;
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (submitFlag) checkFormError();
  }, [email]);

  const onSubmit = errorWrapper(async (event) => {
    event.preventDefault();
    setSubmitFlag(true);
    Loader.show();

    const errorRes = await checkFormError();

    if (errorRes) {
      Loader.hide();
      return;
    }

    const reqBody = {
      email: email.toLowerCase(),
    };

    const success = await sendEmail(reqBody);

    if (success) {
      Loader.hide();
      NotificationManager.success(
        "We've successfully sent a temporary password to your email. Kindly check your inbox and continue with the login process.",
        15000
      );
      history.push(`/user/login`);
    }
  });

  return { email, formError, onChangeEmail, onSubmit };
};

export default useForgotPassword;

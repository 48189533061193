const validateData = async (schema, payload) => {
  if (schema) {
    try {
      await schema["validate"](payload, { abortEarly: false });
      return { success: true, errors: false };
    } catch (err) {
      const errors = {};
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
      return { success: false, errors: errors };
    }
  }
};

export { validateData };

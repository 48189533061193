import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import * as yup from "yup";
import { API_SERVICE } from "../../../api";
import { errorWrapper, handleError } from "../../../helpers";
import { Loader } from "../../../utils/appLoader";
import { NotificationManager } from "../../../utils/notificationManager";
import { validateData, validation } from "../../../validation";

const useResetPassword = () => {
  const location = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const history = useHistory();
  const [formError, setFormError] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  const { email, oldPassword } = location?.state;

  if (email === undefined || oldPassword === undefined) {
    history.push("/error");
  }

  const firstRender = useRef(true);

  let userSchema = yup.object({
    newPassword: validation.password,
    confirmNewPassword: validation.password,
  });

  const onChangeNewPassword = useCallback((e) => {
    setNewPassword(e.target.value);
  }, []);

  const onChangeNewPassword2 = useCallback((e) => {
    setNewPassword2(e.target.value);
  }, []);

  const ChangePassword = (reqBody) => {
    return new Promise((resolve, reject) => {
      API_SERVICE.changePassword(reqBody)
        .then((res) => {
          if (res.httpStatus === "OK") {
            resolve(true);
          } else {
            Loader.hide();
            handleError(res, "Something went wrong");
          }
        })
        .catch((err) => reject(err));
    });
  };

  const checkFormError = async () => {
    let payload = {
      newPassword: newPassword,
      confirmNewPassword: newPassword2,
    };
    const { errors } = await validateData(userSchema, payload);
    if (errors) {
      setFormError(errors);
      return true;
    }
    setFormError(false);
    return false;
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (submitFlag) checkFormError();
  }, [newPassword, newPassword2]);

  const onSubmit = errorWrapper(async (event) => {
    event.preventDefault();
    setSubmitFlag(true);
    Loader.show();

    const errorRes = await checkFormError();

    if (errorRes) {
      Loader.hide();
      return;
    }

    if (newPassword.trim() !== newPassword2.trim()) {
      Loader.hide();
      NotificationManager.error("Passwords do not match");
      return;
    }

    const reqBody = {
      email: email,
      newPassword: newPassword,
      oldPassword: oldPassword,
    };

    const success = await ChangePassword(reqBody);

    if (success) {
      Loader.hide();
      NotificationManager.success("Password changed successfully");
      history.push(`/user/login`);
    }
  });

  return { newPassword, newPassword2, formError, onChangeNewPassword, onChangeNewPassword2, onSubmit };
};

export default useResetPassword;

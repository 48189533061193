import React, { Suspense, useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { AuthRoute, ProtectedRoute } from "./helpers/authHelper";
import LoginScreen from "./views/user/login";
import { NotificationWrapper } from "./utils/notificationManager";
import { LoaderWrapper } from "./utils/appLoader";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Loader from "./components/loader/loader";
import ReportDataProvider from "./context/reportFilterContext";
import ForgotPassword from "./views/user/forgot-password";
import ResetPassword from "./views/user/reset-password";

const ViewApp = React.lazy(() => import("./views/app"));
const ViewError = React.lazy(() => import("./views/error"));
const ViewUnauthorized = React.lazy(() => import("./views/unauthorized"));

const App = () => {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ReportDataProvider>
          <NotificationWrapper>
            <LoaderWrapper>
              <Router>
                <React.StrictMode>
                  <Switch>
                    <ProtectedRoute path="/app" component={ViewApp} />
                    <Redirect exact from="/" to="/app/view-patients" />
                    <AuthRoute path="/user/login" component={LoginScreen} />
                    <AuthRoute path="/user/forgot-password" component={ForgotPassword} />
                    <AuthRoute path="/user/reset-password" component={ResetPassword} />
                    <Route path="/error" exact render={(props) => <ViewError {...props} />} />
                    <Route path="/unauthorized" exact render={(props) => <ViewUnauthorized {...props} />} />
                    <Redirect to="/error" />
                  </Switch>
                </React.StrictMode>
              </Router>
            </LoaderWrapper>
          </NotificationWrapper>
        </ReportDataProvider>
      </LocalizationProvider>
    </Suspense>
  );
};

export default App;

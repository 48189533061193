import { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router";
import { API_ERROR_MESSAGES, adminRoot } from "../../../constants/constants";
import { setCurrentUser } from "../../../helpers/appStorage";
import { NotificationManager } from "../../../utils/notificationManager";
import { Loader } from "../../../utils/appLoader";
import { API_SERVICE } from "../../../api";
import { errorWrapper, handleError } from "../../../helpers";
import * as yup from "yup";
import { validateData, validation } from "../../../validation";
import { useEffect } from "react";

const useLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [formError, setFormError] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  const firstRender = useRef(true);

  let userSchema = yup.object({
    email: validation.email,
    password: validation.password,
  });

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  /**
   * This function will invoke the sign in API and verifies that the user is a valid user. Error should be handled within this function
   * @param {object} inputData
   * @returns {Promise} userData
   */
  const SignInUser = (inputData) => {
    return new Promise((resolve, _reject) => {
      const requestBody = {
        email: inputData.email.trim(),
        password: inputData.password.trim(),
      };
      API_SERVICE.signIn(requestBody)
        .then((res) => resolve(res))
        .catch((err) => {
          Loader.hide();
          if (err.message === "No login match found") return handleError("Invalid Email or Password");
          if (err.message === API_ERROR_MESSAGES.SURGEON_DISABLED)
            return handleError("Your account has been disabled. Please contact the admin to enable it.");

          handleError(err, "Login Error");
        });
    });
  };

  const checkFormError = async () => {
    let payload = {
      email: email,
      password: password,
    };
    const { errors } = await validateData(userSchema, payload);
    if (errors) {
      setFormError(errors);
      return true;
    }
    setFormError(false);
    return false;
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (submitFlag) checkFormError();
  }, [email, password]);

  const onSubmit = errorWrapper(async (event) => {
    event.preventDefault();
    setSubmitFlag(true);
    Loader.show();

    const errorRes = await checkFormError();

    if (errorRes) {
      Loader.hide();
      return;
    }

    const reqBody = {
      email: email.toLowerCase(),
      password: password,
    };

    const userInfo = await SignInUser(reqBody);

    if (userInfo) {
      Loader.hide();
      if (userInfo.passwordSystemGenerated && userInfo.passwordSystemGeneratedExpired) {
        NotificationManager.error("The temporary password has expired. Please request a new one to continue.");
      } else if (userInfo.passwordSystemGenerated) {
        history.push({
          pathname: `/user/reset-password`,
          state: { email: reqBody.email, oldPassword: reqBody.password },
        });
      } else {
        setCurrentUser(userInfo);
        NotificationManager.success("Login successfull");
        history.push(`${adminRoot}/view-patients`);
      }
    }
  });

  return { email, password, formError, onChangeEmail, onChangePassword, onSubmit };
};

export default useLogin;

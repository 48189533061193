import axios from "axios";
import { API_HEADER, BASE_URL } from "./apiConfig";
import { setCurrentUser } from "../helpers";
import { API_ERROR_MESSAGES } from "../constants";

const API = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  headers: API_HEADER,
});

API.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/**
 * Interceptor will not filter the data returned from the server for the given black listed urls
 */
const responseDataFilterBlackList = [];
const disabledMessage = API_ERROR_MESSAGES.SURGEON_DISABLED;
const loginEndpoint = "validateAuth";
const loginUrl = "/user/login";

API.interceptors.response.use(
  function (response) {
    const showFullResponse = responseDataFilterBlackList.includes(response.config.url);
    return showFullResponse ? response : response.data;
  },
  function (error) {
    const errorMessage = error?.response?.data?.message || false;
    const isFromLogin = error.config.url === loginEndpoint ? true : false;
    if (errorMessage && errorMessage === disabledMessage && !isFromLogin) {
      setCurrentUser();
      window.location.href = loginUrl;
    }
    let res = error?.response?.data; // This is the api response error object
    if (res) return Promise.reject(res);
    return Promise.reject(new Error("Server error issue"));
  }
);

export default API;

import React from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import appIcon from "../../../assets/icons/appLogo.png";
import UserLayout from "../../../layout/userLayout";
import useForgotPassword from "./util";

const ForgotPassword = () => {
  const { email, formError, onChangeEmail, onSubmit } = useForgotPassword();

  return (
    <UserLayout>
      <div className="login-card">
        <div className="text-center">
          <img src={appIcon} width="100px" alt="logo" />
        </div>
        <h2 className="auth-title">PROM-IN-ENT</h2>
        <h3 className="login-title">Forgot Password</h3>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Control className="app-auth-input login-input" type="email" placeholder="Enter Email" value={email} onChange={onChangeEmail} />
            {formError.email && <div className="form-error">{formError.email}</div>}
          </Form.Group>

          <div className="forgot-password-container">
            <Link className="forgot-password-link" to="/user/login">
              Back to Login
            </Link>
          </div>
          <div className="text-end">
            <Button className="mt-4 auth-btn" variant="primary" type="submit" onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </Form>
        <ToastContainer />
      </div>
    </UserLayout>
  );
};

export default ForgotPassword;

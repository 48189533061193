import { EventEmitter } from "eventemitter3";

export const EVENT = "NOTIFICATION_EVENT";

export const NotificationType = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export class CustomNotification extends EventEmitter {
  success(message, duration) {
    this.emit(EVENT, { type: NotificationType.SUCCESS, message, duration });
  }
  error(message) {
    this.emit(EVENT, { type: NotificationType.ERROR, message });
  }
  warning(message) {
    this.emit(EVENT, { type: NotificationType.WARNING, message });
  }
  info(message) {
    this.emit(EVENT, { type: NotificationType.INFO, message });
  }
}

import moment from "moment";
import { createContext, useState } from "react";

const defaultData = {
  startDate: moment().subtract("1", "months").toDate(),
  endDate: moment().toDate(),
  category: "",
  survey: "",
  year: moment().toDate(),
};

export const ReportFilterDataContext = createContext({});

export default function ReportDataProvider({ children }) {
  const [filterData, setFilterData] = useState(defaultData);

  const updateFilterData = (data) => setFilterData(data);

  const resetReportFilterData = () => setFilterData(defaultData);

  return (
    <ReportFilterDataContext.Provider value={{ filterData, updateFilterData, resetReportFilterData }}>{children}</ReportFilterDataContext.Provider>
  );
}

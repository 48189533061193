import API from "../utils";
import urls from "../urls";
import { getCurrentUser } from "../../helpers/appStorage";

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getCategories = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_CATEGORY}/${user}`, payload);
};

/**
 *
 * @returns Promise
 */
export const getAllCategories = async () => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_ALL_CATEGORIES}/${user}`);
};

/**
 *
 * @param {string} categoryId - Request params
 * @returns Promise
 */
export const getSurveyByCategory = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_SURVEYBY_CATEGORY}/${user}/${id}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const addPatient = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.ADD_PATIENT}/${user}`, payload);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getAllPatients = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_ALL_PATIENTS}/${user}`, payload);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const updatePatient = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.UPDATE_PATIENT}/${user}`, payload);
};

/**
 *
 * @param {string} patientId - Request params
 * @returns Promise
 */
export const getPatientDetailsById = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_PATIENT_DETAILS}/${user}/${id}`);
};

/**
 *
 * @param {string} surveyId - Request params
 * @returns Promise
 */
export const getSurveyDetailsById = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_SURVEY_DETAILS}/${user}/${id}`);
};

/**
 *
 * @param {string} patientId - Request params
 * @returns Promise
 */
export const enableDisablePatient = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.ENABLE_DISABLE_PATIENT}/${user}/${id}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getAllSurveys = async (payload) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_ALL_SURVEYS}/${user}`, payload);
};

/**
 *
 * @returns Promise
 */
export const getProfile = async () => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_PROFILE}/${user}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getPatientReportDetails = async (payload, patientId) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_PATIENT_REPORT_DETAILS}/${user}/${patientId}`, payload);
};

/**
 *
 * @param {string} id - The body of the request
 * @returns Promise
 */
export const getPatientReportDetailsById = async (id) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.GET_PATIENT_REPORT_DETAILS_BY_ID}/${user}/${id}`);
};

/**
 *
 * @returns Promise
 */
export const acceptPrivacyPolicy = async () => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.get(`${urls.ACCEPT_PRIVACY_POLICY}/${user}`);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getPatientDayGraphReportDetails = async (payload, patientId) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_DAY_GRAPH_REPORT_DETAILS}/${user}/${patientId}`, payload);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getPatientYearGraphReportDetails = async (payload, patientId, year) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_YEARLY_GRAPH_REPORT_DETAILS}/${user}/${patientId}/${year}`, payload);
};

/**
 *
 * @param {object} payload - The body of the request
 * @returns Promise
 */
export const getPatientCSVReportDetails = async (payload, patientId) => {
  const user = getCurrentUser() ? `${getCurrentUser().secretKey}/${getCurrentUser().id}` : null;
  return API.post(`${urls.GET_PATIENT_CSV_REPORT}/${user}/${patientId}`, payload, { responseType: "blob" });
};
